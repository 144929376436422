import { FrameType } from "./types";
import { getFrameType } from "./utils";

// Also update the variables in scss/index.scss

const FRAME_WIDTH_WIDE = 2400;
const FRAME_HEIGHT_WIDE = 690;
const FIRST_FRAME_OVERFLOW_ID_WIDE = 90;
const FIRST_FRAME_ID_WIDE = 180;
const LAST_FRAME_ID_WIDE = 772;
const LAST_FRAME_OVERFLOW_ID_WIDE = 870;
const NUMBER_OF_SLIDES_WIDE = 6;
const NUMBER_PADDING_OF_FRAME_WIDE = 3; // 001
const MAXIMUM_NUMBER_OF_NETWORK_THREADS_WIDE = 20;

const FRAME_WIDTH_DESKTOP = 1920;
const FRAME_HEIGHT_DESKTOP = 700;
const FIRST_FRAME_OVERFLOW_ID_DESKTOP = 61;
const FIRST_FRAME_ID_DESKTOP = 180;
const LAST_FRAME_ID_DESKTOP = 805;
const LAST_FRAME_OVERFLOW_ID_DESKTOP = 900;
const NUMBER_OF_SLIDES_DESKTOP = 6;
const NUMBER_PADDING_OF_FRAME_DESKTOP = 3; // 001
const MAXIMUM_NUMBER_OF_NETWORK_THREADS_DESKTOP = 20;

const FRAME_WIDTH_MOBILE = 1080;
const FRAME_HEIGHT_MOBILE = 900;
const FIRST_FRAME_OVERFLOW_ID_MOBILE = 120;
const FIRST_FRAME_ID_MOBILE = 180;
const LAST_FRAME_ID_MOBILE = 780;
const LAST_FRAME_OVERFLOW_ID_MOBILE = 850;
const NUMBER_OF_SLIDES_MOBILE = 6;
const NUMBER_PADDING_OF_FRAME_MOBILE = 3; // 001
const MAXIMUM_NUMBER_OF_NETWORK_THREADS_MOBILE = 20;

const CONFIG_WIDE = {
  FRAME_WIDTH: FRAME_WIDTH_WIDE,
  FRAME_HEIGHT: FRAME_HEIGHT_WIDE,
  FIRST_FRAME_OVERFLOW_ID: FIRST_FRAME_OVERFLOW_ID_WIDE,
  FIRST_FRAME_ID: FIRST_FRAME_ID_WIDE,
  LAST_FRAME_ID: LAST_FRAME_ID_WIDE,
  LAST_FRAME_OVERFLOW_ID: LAST_FRAME_OVERFLOW_ID_WIDE,
  NUMBER_OF_SLIDES: NUMBER_OF_SLIDES_WIDE,
  NUMBER_PADDING_OF_FRAME: NUMBER_PADDING_OF_FRAME_WIDE,
  MAXIMUM_NUMBER_OF_NETWORK_THREADS: MAXIMUM_NUMBER_OF_NETWORK_THREADS_WIDE,
};
const CONFIG_DESKTOP = {
  FRAME_WIDTH: FRAME_WIDTH_DESKTOP,
  FRAME_HEIGHT: FRAME_HEIGHT_DESKTOP,
  FIRST_FRAME_OVERFLOW_ID: FIRST_FRAME_OVERFLOW_ID_DESKTOP,
  FIRST_FRAME_ID: FIRST_FRAME_ID_DESKTOP,
  LAST_FRAME_ID: LAST_FRAME_ID_DESKTOP,
  LAST_FRAME_OVERFLOW_ID: LAST_FRAME_OVERFLOW_ID_DESKTOP,
  NUMBER_OF_SLIDES: NUMBER_OF_SLIDES_DESKTOP,
  NUMBER_PADDING_OF_FRAME: NUMBER_PADDING_OF_FRAME_DESKTOP,
  MAXIMUM_NUMBER_OF_NETWORK_THREADS: MAXIMUM_NUMBER_OF_NETWORK_THREADS_DESKTOP,
};
const CONFIG_MOBILE = {
  FRAME_WIDTH: FRAME_WIDTH_MOBILE,
  FRAME_HEIGHT: FRAME_HEIGHT_MOBILE,
  FIRST_FRAME_OVERFLOW_ID: FIRST_FRAME_OVERFLOW_ID_MOBILE,
  FIRST_FRAME_ID: FIRST_FRAME_ID_MOBILE,
  LAST_FRAME_ID: LAST_FRAME_ID_MOBILE,
  LAST_FRAME_OVERFLOW_ID: LAST_FRAME_OVERFLOW_ID_MOBILE,
  NUMBER_OF_SLIDES: NUMBER_OF_SLIDES_MOBILE,
  NUMBER_PADDING_OF_FRAME: NUMBER_PADDING_OF_FRAME_MOBILE,
  MAXIMUM_NUMBER_OF_NETWORK_THREADS: MAXIMUM_NUMBER_OF_NETWORK_THREADS_MOBILE,
};

// Get the configuration for screen size
export const getConfig = (frameType = getFrameType()) => {
  switch (frameType) {
    case FrameType.WIDE:
      return CONFIG_WIDE;
    case FrameType.DESKTOP:
      return CONFIG_DESKTOP;
    case FrameType.MOBILE:
      return CONFIG_MOBILE;
    default:
      return CONFIG_MOBILE;
  }
};
