import { FrameType } from "./types";

// Calculates the max width and height to stay within the aspect ratio
export function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number
) {
  let ratio = maxWidth / srcWidth;

  return { width: maxWidth, height: srcHeight * ratio };
}

// Performs N tasks concurrently based on the results of the generator
export const concurrentTaskPool = async <T, Args extends any[]>(
  generator: Generator<T>,
  concurrent: number,
  callback: (gen: T, ...args: Args) => Promise<any>,
  args: Args
) => {
  await new Promise((res) => {
    const handler = () => {
      const gen = generator.next();
      // Start another instance as soon as the first one completes
      if (!gen.done) callback(gen.value, ...args).then(handler);
      else res(true);
    };

    // Create the simultaneous threads
    for (let i = 0; i < concurrent; i++) handler();
  });
};

// Checks whether the website is loading on desktop or mobile
export const getFrameType = () => window.frameType;

export const getFrameVersion = (element: HTMLElement) =>
  element.scrollWidth > 1280
    ? FrameType.WIDE
    : element.scrollWidth >= 768
    ? FrameType.DESKTOP
    : FrameType.MOBILE;
