{
  "boards": [
    {
      "board-carrousel-series": "Get pumped",
      "board-carrousel-name": "Flite AIR",
      "board-carrousel-description": " Smaller cost. \nBig payoff. \n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$6,995",
            "CAD": "$9,444 CAD"
          },
          "EU": {
            "DEFAULT": "€6.700 *exc VAT",
            "GBP": "£6,995 GBP",
            "AT": "€8.040",
            "BE": "€8.107",
            "BG": "€8.040",
            "HR": "€8.376",
            "CY": "€7.973",
            "CZ": "€8.107",
            "DK": "€8.376",
            "EE": "€8.173",
            "FI": "€8.308",
            "FR": "€8.040",
            "DE": "€7.973",
            "GR": "€8.308",
            "HU": "€8.509",
            "MC": "€8.040",
            "IE": "€8.241",
            "IT": "€8.173",
            "LV": "€8.107",
            "LT": "€8.107",
            "LU": "€7.839",
            "MT": "€7.907",
            "NL": "€8.107",
            "NO": "€6.700",
            "PL": "€8.241",
            "PT": "€8.241",
            "RO": "€7.973",
            "SK": "€8.040",
            "SI": "€8.173",
            "ES": "€8.107",
            "SE": "€8.376",
            "CH": "€6.700"
          },
          "AU": {
            "DEFAULT": "$10,999",
            "NZD": "$12,419 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/con-flite-air-yuzu"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/pages/flite-air"
        }
      ]
    },
    {
      "board-carrousel-series": "Limited Edition",
      "board-carrousel-name": "<img src=\"https://d1nm0m7wgloxp4.cloudfront.net/limited-edition.png\" width=\"300\" alt=\"Fliteboard x Marc Newson\" class=\"limited-edition\" />",
      "board-carrousel-description": "Pure design. \nPure performance.\n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$17,495",
            "CAD": "$23,621 CAD"
          },
          "EU": {
            "DEFAULT": "€16.500 *exc VAT",
            "GBP": "£17,225 GBP",
            "AT": "€19.800",
            "BE": "€19.966",
            "BG": "€19.800",
            "HR": "€20.626",
            "CY": "€19.635",
            "CZ": "€19.966",
            "DK": "€20.626",
            "EE": "€20.130",
            "FI": "€20.460",
            "FR": "€19.800",
            "DE": "€19.635",
            "GR": "€20.460",
            "HU": "€20.955",
            "MC": "€19.800",
            "IE": "€20.296",
            "IT": "€20.130",
            "LV": "€19.966",
            "LT": "€19.966",
            "LU": "€19.305",
            "MT": "€19.470",
            "NL": "€19.966",
            "NO": "€16.500",
            "PL": "€20.296",
            "PT": "€20.296",
            "RO": "€19.635",
            "SK": "€19.800",
            "SI": "€20.130",
            "ES": "€19.966",
            "SE": "€20.626",
            "CH": "€16.500"
          },
          "AU": {
            "DEFAULT": "$28,999",
            "NZD": "$32,743 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/con-mn60-wave"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/pages/marcnewson"
        }
      ]
    },
    {
      "board-carrousel-series": "Versatile",
      "board-carrousel-name": "Fliteboard",
      "board-carrousel-description": "Our best all-rounder for all \nlevels of riding experience. \n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$8,995",
            "CAD": "$12,145 CAD"
          },
          "EU": {
            "DEFAULT": "€8,700 *exc VAT",
            "GBP": "£9,083 GBP",
            "AT": "€10.440",
            "BE": "€10.527",
            "BG": "€10.440",
            "HR": "€10.876",
            "CY": "€10.353",
            "CZ": "€10.527",
            "DK": "€10.876",
            "EE": "€10.613",
            "FI": "€10.788",
            "FR": "€10.440",
            "DE": "€10.353",
            "GR": "€10.788",
            "HU": "€11.049",
            "MC": "€10.440",
            "IE": "€10.701",
            "IT": "€10.613",
            "LV": "€10.527",
            "LT": "€10.527",
            "LU": "€10.179",
            "MT": "€10.267",
            "NL": "€10.527",
            "NO": "€8.700",
            "PL": "€10.701",
            "PT": "€10.701",
            "RO": "€10.353",
            "SK": "€10.440",
            "SI": "€10.613",
            "ES": "€10.527",
            "SE": "€10.876",
            "CH": "€8.700"
          },
          "AU": {
            "DEFAULT": "$14,499",
            "NZD": "$16,370 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/con-fliteboard-fibreglass-white"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/pages/fliteboard"
        }
      ]
    },
    {
      "board-carrousel-series": "More responsive",
      "board-carrousel-name": "PRO",
      "board-carrousel-description": "Freestyle ready,\n to level up your riding.\n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$8,995",
            "CAD": "$12,145 CAD"
          },
          "EU": {
            "DEFAULT": "€8.700 *exc VAT",
            "GBP": "£9,083 GBP",
            "AT": "€10.440",
            "BE": "€10.527",
            "BG": "€10.440",
            "HR": "€10.876",
            "CY": "€10.353",
            "CZ": "€10.527",
            "DK": "€10.876",
            "EE": "€10.613",
            "FI": "€10.788",
            "FR": "€10.440",
            "DE": "€10.353",
            "GR": "€10.788",
            "HU": "€11.049",
            "MC": "€10.440",
            "IE": "€10.701",
            "IT": "€10.613",
            "LV": "€10.527",
            "LT": "€10.527",
            "LU": "€10.179",
            "MT": "€10.267",
            "NL": "€10.527",
            "NO": "€8.700",
            "PL": "€10.701",
            "PT": "€10.701",
            "RO": "€10.353",
            "SK": "€10.440",
            "SI": "€10.613",
            "ES": "€10.527",
            "SE": "€10.876",
            "CH": "€8.700"
          },
          "AU": {
            "DEFAULT": "$14,499",
            "NZD": "$16,370 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/con-pro-carbon-ash"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/pages/pro"
        }
      ]
    },
    {
      "board-carrousel-series": "Innovation",
      "board-carrousel-name": "ULTRA <span class=\"orange\">/</span> L2",
      "board-carrousel-description": "Ride the future. \nPush your ride to the extreme.\n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$13,995",
            "CAD": "$18,896 CAD"
          },
          "EU": {
            "DEFAULT": "€13.500 *exc VAT",
            "GBP": "£14,094 GBP",
            "AT": "€16.200",
            "BE": "€16.335",
            "BG": "€16.200",
            "HR": "€16.876",
            "CY": "€16.065",
            "CZ": "€16.335",
            "DK": "€16.876",
            "EE": "€16.469",
            "FI": "€16.740",
            "FR": "€16.200",
            "DE": "€16.065",
            "GR": "€16.740",
            "HU": "€17.145",
            "MC": "€16.200",
            "IE": "€16.605",
            "IT": "€16.469",
            "LV": "€16.335",
            "LT": "€16.335",
            "LU": "€15.795",
            "MT": "€15.931",
            "NL": "€16.335",
            "NO": "€13.500",
            "PL": "€16.605",
            "PT": "€16.605",
            "RO": "€16.065",
            "SK": "€16.200",
            "SI": "€16.469",
            "ES": "€16.335",
            "SE": "€16.876",
            "CH": "€13.500"
          },
          "AU": {
            "DEFAULT": "$22,999",
            "NZD": "$32,742 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/ultra-l-2-ultra-marine?wave"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/pages/ultra-l2"
        }
      ]
    },
    {
      "board-carrousel-series": "Easy flying",
      "board-carrousel-name": "Flitescooter",
      "board-carrousel-description": "Step on and go. \nNow everyone can fly.\n \n",
      "board-carrousel-price": {
        "title": "Starting from",
        "region": {
          "US": {
            "DEFAULT": "$12,495",
            "CAD": "$16,871 CAD"
          },
          "EU": {
            "DEFAULT": "€12.000 *exc VAT",
            "GBP": "£12,528 GBP",
            "AT": "€14.400",
            "BE": "€14.520",
            "BG": "€14.400",
            "HR": "€15.000",
            "CY": "€14.280",
            "CZ": "€14.520",
            "DK": "€15.000",
            "EE": "€14.640",
            "FI": "€14.880",
            "FR": "€14.400",
            "DE": "€14.280",
            "GR": "€14.880",
            "HU": "€15.240",
            "MC": "€14.400",
            "IE": "€14.761",
            "IT": "€14.640",
            "LV": "€14.520",
            "LT": "€14.520",
            "LU": "€14.039",
            "MT": "€14.160",
            "NL": "€14.520",
            "NO": "€12.000",
            "PL": "€14.761",
            "PT": "€14.761",
            "RO": "€14.280",
            "SK": "€14.400",
            "SI": "€14.640",
            "ES": "€14.520",
            "SE": "€15.000",
            "CH": "€12.000"
          },
          "AU": {
            "DEFAULT": "$19,599",
            "NZD": "$22,129 NZD"
          }
        }
      },
      "board-carrousel-cta": [
        {
          "id": "board-carrousel-cta1",
          "title": "Buy Now",
          "url": "/products/flitescooter-oyster"
        },
        {
          "id": "board-carrousel-cta2",
          "title": "Explore",
          "url": "/flitescooter"
        }
      ]
    }
  ]
}
