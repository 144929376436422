import { Autoplay, Navigation, Pagination, Swiper } from "swiper";
import { generateTextAnimation, updateTextAnimation } from "./animations";
import { preloadFrames, renderFrame } from "./imageHandler";

import type { SwiperModule } from "swiper/types";
import {
  calculateAspectRatioFit,
  getFrameVersion as _getFrameVersion,
} from "./utils";
import { getConfig } from "./config";
import gsap from "gsap";
import { setAllText } from "./textHandler";
import { setupFAB } from "./fabConfig";
import { removeSkeleton } from "./loadingHelpers";

window.addEventListener("load", () => {
  const getInstantStep = () => (1 / (getConfig().NUMBER_OF_SLIDES - 1)) * 0.99;

  // Gets the frame type based on the current width
  const getFrameVersion = () => _getFrameVersion(canvasWrapper);

  let swiper: Swiper;

  let swiperProgress = 0;
  let videoProgress = 0;
  let gsapCarrouselAnimation: gsap.core.Tween | undefined;
  let gsapTextAnimationTimeline = generateTextAnimation();

  const overlayContent = document.getElementById(
    "board-carrousel-overlayContent"
  ) as HTMLParagraphElement;
  const overlayWrapper = document.getElementById(
    "board-carrousel-overlayWrapper"
  ) as HTMLDivElement;
  const canvasWrapper = document.getElementById(
    "board-carrousel-canvasWrapper"
  ) as HTMLDivElement;
  const boardCanvas = document.getElementById(
    "board-carrousel-boardCanvas"
  ) as HTMLCanvasElement;
  const ctx = boardCanvas.getContext("2d");

  // Defaults to mobile version
  window.frameType = getFrameVersion();

  let canvasWidth = getConfig().FRAME_WIDTH;
  let canvasHeight = getConfig().FRAME_HEIGHT;

  // Used to load the frames for the current platform
  const loadFrames = () => {
    const framesGenerator = preloadFrames();
    // preloads the keyframes
    framesGenerator.next().then(() => setVideoProgress(videoProgress));
    // preloads the rest of the frames
    framesGenerator.next();
    // preloads the overflow frames
    framesGenerator.next();
  };

  // Updates the slides that are used by swiper
  const updateSlides = () => {
    const numberOfSlides = getConfig().NUMBER_OF_SLIDES;

    // Get the current slide count
    const swiperWrapper = document.querySelector(
      ".board-carrousel-swiper-wrapper"
    );
    const oldNumberOfSlides = swiperWrapper?.children.length ?? 1;

    if (numberOfSlides == oldNumberOfSlides) return;

    // Removes the old slides
    Array.from(
      document.getElementsByClassName("board-carrousel-swiper-dummy")
    ).forEach((element) => {
      swiperWrapper?.removeChild(element);
    });

    // Adds the new slide count
    for (let index = 0; index < numberOfSlides - 1; index++) {
      const element = document.createElement("div");
      element.className =
        "board-carrousel-swiper-dummy board-carrousel-swiper-slide";
      swiperWrapper?.appendChild(element);
    }

    // Informing swiper of a change to the number of slides
    swiper.updateSlides();
    swiper.updateProgress();

    // Clamp the progress to be a max 1
    // This eliminates overflow when transitioning
    swiper.setProgress(Math.min(swiper.progress, 1));
    swiper.updateProgress();
    swiper.updateSlidesClasses();
  };

  // Needed for it to work on HDPI Screens
  const setCanvasSize = () => {
    // Scaling factor
    const dpr = window.devicePixelRatio || 1;

    // Set whether to use the wide/desktop/mobile version
    window.frameType = getFrameVersion();

    // Aspect ratio size
    const size = calculateAspectRatioFit(
      getConfig().FRAME_WIDTH,
      getConfig().FRAME_HEIGHT,
      canvasWrapper.getBoundingClientRect().width
    );

    // Scale the canvas by dpr
    boardCanvas.width = size.width * dpr;
    boardCanvas.height = size.height * dpr;

    // Update canvas width and height
    canvasWidth = size.width;
    canvasHeight = size.height;

    // Set the canvas size in px
    boardCanvas.style.width = canvasWidth + "px";
    boardCanvas.style.height = canvasHeight + "px";

    // Scale the canvas
    ctx?.scale(dpr, dpr);

    // Update slides
    updateSlides();

    // re-render the current frame
    setVideoProgress(videoProgress);

    // Loads the frames
    loadFrames();
  };

  const updateOverlayHeight = () => {
    const height = overlayContent.scrollHeight + "px";
    if (height !== overlayWrapper.style.height)
      overlayWrapper.style.height = height;
  };

  // Sets the video progress and update the UI
  const setVideoProgress = (progress: number) => {
    videoProgress = progress;
    if (ctx) renderFrame(ctx, videoProgress, canvasWidth, canvasHeight);
    updateTextAnimation(gsapTextAnimationTimeline, videoProgress);
    updateOverlayHeight();
  };

  // Custom plugin to allow for video scrubbing
  const videoScrubPlugin: SwiperModule = ({ on }: Swiper) => {
    on("setTransition", (swiper, transition) => {
      const durationInSeconds = transition / 1000;

      // Used to interpolate between the current video frame and the target frame
      const interpolate = gsap.utils.interpolate(
        videoProgress,
        swiper.progress
      );

      // Kills the previous animation to avoid interference if they overlap
      gsapCarrouselAnimation?.kill();

      // Used to as a tween to interpolate the animation
      gsapCarrouselAnimation = gsap.to(
        {},
        {
          duration: durationInSeconds,
          onUpdate() {
            setVideoProgress(interpolate(this.ratio));
          },
          ease: "power1.out",
        }
      );
    });

    // Called when there is dragging/change in slide
    // This is not called when animating
    on("progress", (swiper, progress) => {
      // Used to avoid jumping when double pressing navigation button
      // Must be a value close to the progress step
      if (Math.abs(swiperProgress - progress) < getInstantStep())
        setVideoProgress(swiperProgress);
      swiperProgress = progress;
    });
  };

  // Init Swiper
  swiper = new Swiper(".board-carrousel-swiper", {
    // The class of the slides in the dom
    slideClass: "board-carrousel-swiper-slide",
    // The class of the wrapper in the dom
    wrapperClass: "board-carrousel-swiper-wrapper",
    // Install Plugin To Swiper
    modules: [Autoplay, Navigation, Pagination, videoScrubPlugin],
    // Animation speed in ms
    speed: 750,
    // Shows cursor as grab
    grabCursor: true,
    // Side arrows
    navigation: {
      nextEl: ".board-carrousel-swiper-button-next",
      prevEl: ".board-carrousel-swiper-button-prev",
    },
    // Pagination Dots
    pagination: {
      enabled: true,
      clickable: true,
      el: ".board-carrousel-swiper-pagination",
      bulletClass: "board-carrousel-swiper-pagination-bullet",
      horizontalClass: "board-carrousel-swiper-pagination-horizontal",
      bulletActiveClass: "board-carrousel-swiper-pagination-bullet-active",
    },
    // Disables transition animation
    virtualTranslate: true,
    // Updates progress on every slide
    watchSlidesProgress: true,
    // Adds touch ratio, makes the carrousel
    // travel more distance while dragging
    touchRatio: 1.5,
    // The amount of pixels needed
    // to be dragged before moving
    threshold: 1,
    // Animates the carrousel automatically once every 5 seconds
    autoplay: {
      delay: 5000,
      waitForTransition: false,
      disableOnInteraction: true,
    },
  });

  // Preloads the frames
  loadFrames();

  // Sets the text on the screen
  setAllText();

  // Monitors the canvas wrapper for size change to update the canvas size
  new ResizeObserver(setCanvasSize).observe(canvasWrapper);
  setCanvasSize();

  // Starts the autoplay of the carrousel
  setTimeout(() => swiper.autoplay.start(), 10000);

  // Configures and shows the config FAB
  setupFAB();

  // Remove the skeleton loader
  removeSkeleton();
});

// Animates in the section in shopify
document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    const section = document.getElementById("section-{{ section.id }}");
    if (section) section.style.opacity = "1";
  }, 500);
});
