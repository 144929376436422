import { calculateTextIndex, setText } from "./textHandler";

import { getConfig } from "./config";
import gsap from "gsap";
import { BoardDataKeys } from "./parseData";

let progress = 0;

export const generateTextAnimation = () => {
  const animationDuration = 2;
  let animation = gsap.timeline({
    paused: true,
  });
  const animatable: BoardDataKeys[] = [
    "board-carrousel-series",
    "board-carrousel-name",
    "board-carrousel-description",
    "board-carrousel-price",
    "board-carrousel-cta",
  ];

  animation = animatable.reduce((animation, elem, animationElementIndex) => {
    return animation
      .fromTo(
        `.${elem}`,
        {
          opacity: 1,
          x: 0,
          scale: 1,
        },
        {
          opacity: 0,
          x: -100,
          scale: 0.9,
          duration: animationDuration / 2,
          ease: "none",
          onUpdate: () => {
            setText(
              elem,
              calculateTextIndex(progress, animation.progress(), true)
            );
          },
        },
        animationElementIndex * 0.05 * animationDuration
      )
      .fromTo(
        `.${elem}`,
        {
          opacity: 0,
          x: 50,
          scale: 1,
        },
        {
          opacity: 1,
          x: 0,
          scale: 1,
          duration: animationDuration / 2,
          ease: "none",
          immediateRender: false,
          onUpdate: () => {
            setText(
              elem,
              calculateTextIndex(progress, animation.progress(), false)
            );
          },
        }
      );
  }, animation);

  return animation;
};

// Used to update the text animation
export const updateTextAnimation = (
  tl: gsap.core.Timeline,
  _progress: number
) => {
  // Clamping the progress between 0 and 1
  progress = Math.max(Math.min(_progress, 1), 0);

  // Gets the animation progress based on the total progress
  // The animationProgress goes between 0 and 1 for every slide
  // ex: 5 slides, in the middle of the second slide,
  // _progress will be 0.625 but animationProgress will be 0.5
  const p = progress * (getConfig().NUMBER_OF_SLIDES - 1);
  const animationProgress = p - Math.floor(p);

  tl.progress(animationProgress);
};
