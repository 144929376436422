import { concurrentTaskPool, getFrameType } from "./utils";

import { FrameType } from "./types";
import { getConfig } from "./config";
import { retry } from "@lifeomic/attempt";

const getNumberOfFrames = (frameType?: FrameType) =>
  getConfig(frameType).LAST_FRAME_ID - getConfig(frameType).FIRST_FRAME_ID;

const preloadedFramesWide = new Array(
  getConfig(FrameType.WIDE).LAST_FRAME_OVERFLOW_ID -
    getConfig(FrameType.WIDE).FIRST_FRAME_OVERFLOW_ID
);
const preloadedFramesDesktop = new Array(
  getConfig(FrameType.DESKTOP).LAST_FRAME_OVERFLOW_ID -
    getConfig(FrameType.DESKTOP).FIRST_FRAME_OVERFLOW_ID
);
const preloadedFramesMobile = new Array(
  getConfig(FrameType.MOBILE).LAST_FRAME_OVERFLOW_ID -
    getConfig(FrameType.MOBILE).FIRST_FRAME_OVERFLOW_ID
);

// Used to prevent duplicate preloading of frames
let widePreloading = false;
let desktopPreloading = false;
let mobilePreloading = false;

// Get the preloaded frames
const getPreloadedFrames = (_frameType = getFrameType()) =>
  _frameType === FrameType.WIDE
    ? preloadedFramesWide
    : _frameType === FrameType.DESKTOP
    ? preloadedFramesDesktop
    : preloadedFramesMobile;

// Gets the url of the frame
const getFrameUrl = (frame: number, frameType: FrameType) => {
  const frameId = String(frame.toFixed(0)).padStart(
    getConfig(frameType).NUMBER_PADDING_OF_FRAME,
    "0"
  );

  if (frameType == FrameType.WIDE) {
    return `https://d1nm0m7wgloxp4.cloudfront.net/flite_wide_v8/flite_wide_${frameId}.webp`;
  } else if (frameType == FrameType.DESKTOP) {
    return `https://d1nm0m7wgloxp4.cloudfront.net/flite_desktop_v8/flite_desktop_${frameId}.webp`;
  } else if (frameType == FrameType.MOBILE) {
    return `https://d1nm0m7wgloxp4.cloudfront.net/flite_mobile_v8/flite_mobile_${frameId}.webp`;
  }
  return "";
};

// Updates the frame
export const renderFrame = async (
  ctx: CanvasRenderingContext2D,
  progress: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  const frame =
    getPreloadedFrames()[
      (
        Math.max(
          Math.min(
            progress * getNumberOfFrames() + getConfig().FIRST_FRAME_ID,
            getConfig().LAST_FRAME_OVERFLOW_ID
          ),
          getConfig().FIRST_FRAME_OVERFLOW_ID
        ) - getConfig().FIRST_FRAME_OVERFLOW_ID
      ).toFixed(0)
    ];
  if (frame) {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx?.drawImage(frame, 0, 0, canvasWidth, canvasHeight);
  }
};

const loadFrame = async (frame: number, frameType: FrameType) => {
  const preloadedFrames = getPreloadedFrames(frameType);

  if (preloadedFrames[frame - getConfig().FIRST_FRAME_OVERFLOW_ID])
    return Promise.resolve(
      preloadedFrames[frame - getConfig().FIRST_FRAME_OVERFLOW_ID]
    );

  return retry(
    () =>
      new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(false);
        img.src = getFrameUrl(frame, frameType);
      }),
    {
      delay: 500,
      factor: 2,
      maxAttempts: 4,
      minDelay: 250,
    }
  ).then(
    (img) =>
      (preloadedFrames[frame - getConfig(frameType).FIRST_FRAME_OVERFLOW_ID] =
        img)
  );
};

// This function returns the keyframe frameId based on it's index
// First keyframe: First_Frame
// Keyframes: In between frames divided equally
// Last keyframe: Last_Frame
function getKeyFrame(keyframe: number, frameType: FrameType) {
  return Math.floor(
    (keyframe * getNumberOfFrames(frameType)) /
      (getConfig(frameType).NUMBER_OF_SLIDES - 1) +
      getConfig(frameType).FIRST_FRAME_ID
  );
}

// This function generates the id of the frame, by transforming the range of
// ids into a balanced binary search tree, and then yielding the id by using
// the Level Order Traversal Algorithm
type Middles = { start: number; end: number };
function* preloadFrameIdRangeGenerator(startId: number, endId: number) {
  // Max number of elements in middles array:
  // 2^(ceil(log_2(lastId - startId + 1) - 1))
  // LaTeX Equation
  // 2^{(\lceil{log_2{(\text{endId}-\text{startId}+1)-1}}\rceil)}
  let middles: Middles[] = [{ start: startId, end: endId }];

  while (middles.length > 0) {
    const { start, end } = middles.shift()!;

    const middle = start == end ? start : Math.floor(start + (end - start) / 2);

    const newMiddles: Middles[] = [];
    if (start != middle) newMiddles.push({ start: start, end: middle - 1 });
    if (end != middle) newMiddles.push({ start: middle + 1, end: end });

    // Prevents sequential loading on the next loop
    if (middle % 2 != 0) newMiddles.reverse();
    middles.push(...newMiddles);

    yield middle;
  }

  return 0;
}

// Divides the frames into multiple partitions to increase speed
// This is required since the bst loading algorithm favors the values
// that are closer to the middle, by dividing it into frame partitions
// we can benefit from the fact that it is prioritizing the middle to
// fetch the filler frames between the partitions
function* preloadFrameIdGenerator(frameType: FrameType) {
  let lastFrameId = getConfig(frameType).FIRST_FRAME_ID;
  const frameGenerators: Generator<number, number>[] = [];

  for (let i = 1; i < getConfig(frameType).NUMBER_OF_SLIDES; i++) {
    const frameId = getKeyFrame(i, frameType);
    // The start and end frames are offset by 1 since we already the keyframe
    // This insures that we will not load the same frame twice
    frameGenerators.push(
      preloadFrameIdRangeGenerator(lastFrameId + 1, frameId - 1)
    );
    lastFrameId = frameId;
  }

  // Goes over all the generators in a round robin style
  // Only terminates when all the generators are finished
  let framesRemaining: boolean;
  do {
    framesRemaining = false;
    for (const gen of frameGenerators) {
      const genRes = gen.next();
      if (!genRes.done) {
        framesRemaining = true;
        yield genRes.value;
      }
    }
  } while (framesRemaining);
}

// Used to preload the frames that are
// before the first board & after the last board
function* preloadOverflowFrameIdGenerator(frameType: FrameType) {
  let i = getConfig(frameType).FIRST_FRAME_ID - 1;
  let j = getConfig(frameType).LAST_FRAME_ID + 1;

  while (
    i >= getConfig(frameType).FIRST_FRAME_OVERFLOW_ID ||
    j <= getConfig(frameType).LAST_FRAME_OVERFLOW_ID
  ) {
    if (i >= getConfig(frameType).FIRST_FRAME_OVERFLOW_ID) yield i--;
    if (j <= getConfig(frameType).LAST_FRAME_OVERFLOW_ID) yield j++;
  }

  return getConfig(frameType).FIRST_FRAME_OVERFLOW_ID;
}

// Preloads all the images using the bst approach
export async function* preloadFrames() {
  const _frameType = getFrameType();
  if (
    (_frameType === FrameType.WIDE && widePreloading) ||
    (_frameType === FrameType.DESKTOP && desktopPreloading) ||
    (_frameType === FrameType.MOBILE && mobilePreloading)
  )
    return;

  if (_frameType === FrameType.WIDE) widePreloading = true;
  else if (_frameType === FrameType.DESKTOP) desktopPreloading = true;
  else if (_frameType === FrameType.MOBILE) mobilePreloading = true;

  let queue: Promise<unknown>[] = [];

  // Preloads the keyframes first
  // This ensure that the main board images are loaded first
  for (let i = 0; i < getConfig(_frameType).NUMBER_OF_SLIDES; i++) {
    const frameId = getKeyFrame(i, _frameType);
    queue.push(loadFrame(frameId, _frameType));
  }
  // Waits for all of the keyframes to load
  await Promise.all(queue).catch(() => ({}));

  yield;

  const frameIdGenerator = preloadFrameIdGenerator(_frameType);

  await concurrentTaskPool(
    frameIdGenerator,
    getConfig(_frameType).MAXIMUM_NUMBER_OF_NETWORK_THREADS,
    loadFrame,
    [_frameType]
  );

  yield;

  const overflowFrameIdGenerator = preloadOverflowFrameIdGenerator(_frameType);

  await concurrentTaskPool(
    overflowFrameIdGenerator,
    getConfig(_frameType).MAXIMUM_NUMBER_OF_NETWORK_THREADS,
    loadFrame,
    [_frameType]
  );

  return;
}
