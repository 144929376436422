import { getConfig } from "./config";
import { BoardData, BoardDataKeys } from "./parseData";

type BoardCTA = "board-carrousel-cta";
type BoardText = Exclude<BoardDataKeys, BoardCTA>;

// Returns the index of the text to be shown based on
// the animation progress and the overall progress
export const calculateTextIndex = (
  totalProgress: number,
  slideProgress: number,
  floor = true
) => {
  return (
    Math.floor(totalProgress * (getConfig().NUMBER_OF_SLIDES - 1)) +
    (floor ? Math.floor(slideProgress) : Math.ceil(slideProgress))
  );
};

// Sets the text fields of a board
export const setAllText = (index = 0) => {
  const boards = BoardData.boards[index];
  Object.keys(boards).forEach((key) => {
    setText(key as BoardDataKeys, index);
  });
};

export const setText = (item: BoardText | BoardCTA, index: number) => {
  const data = BoardData.boards[index][item];
  if (typeof data === "string") {
    const element = document.getElementById(item);
    if (element) element.innerHTML = data;
  } else {
    // Loop through all the links and set the data
    data.forEach((data) => {
      const anchorElement = document.getElementById(
        data.id
      ) as HTMLAnchorElement | null;
      if (anchorElement) {
        anchorElement.innerHTML = data.title;
        anchorElement.href = data.url;
      }
    });
  }
};
