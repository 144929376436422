import { shopifyConfig } from "./shopifyConfig";
import _BoardData from "../assets/data.json";
import { ShopifyConfig } from "./shopifyConfigType";

type _Data = typeof _BoardData;

type BoardData = Omit<_Data["boards"][number], "board-carrousel-price"> & {
  "board-carrousel-price": string;
};
export type Data = {
  boards: BoardData[];
};
export type BoardDataKeys = keyof BoardData;

const getDataBasedOnRegion = (region: ShopifyConfig["region"]): Data => {
  const currentUrl = window.location.href;
  const data = _BoardData.boards.map((board: _Data["boards"][number]) => {
    const prices = board["board-carrousel-price"].region[region];
    const isCountryPriceAdded = Object.keys(prices).includes(
      shopifyConfig.country
    );
    const isCurrencyPriceAdded = Object.keys(prices).includes(
      shopifyConfig.currency
    );

    let price = prices.DEFAULT;

    // Priority to country over currency if it is not default
    if (isCountryPriceAdded && shopifyConfig.country !== "DEFAULT") {
      price = prices[shopifyConfig.country];
    } else if (isCurrencyPriceAdded) {
      price = prices[shopifyConfig.currency];
    }

    return {
      ...board,
      "board-carrousel-price": `${board["board-carrousel-price"].title} ${price}`,
      "board-carrousel-cta": board["board-carrousel-cta"].map((cta) => {
        const url = new URL(document.baseURI);
        const ctaUrl = new URL(
          `${url.pathname.replace(/\/$/, "")}${cta.url}`,
          url.toString()
        );

        return {
          ...cta,
          url: ctaUrl.href,
        };
      }),
    };
  });

  return { boards: data };
};

export const BoardData = getDataBasedOnRegion(shopifyConfig.region);
