import { ShopifySetting } from "./shopifyConfig";

export const shopifyThemeConfigs: ShopifySetting[] = [
  {
    id: "region",
    label: "Region",
    isRuntime: false,
    variable: "{{ section.settings.region }}",
    options: {
      US: "US & Global",
      AU: "Australia",
      EU: "Europe",
    },
    default: "US",
  },
  {
    id: "currency",
    label: "Currency",
    isRuntime: true,
    variable: "{{ localization.country.currency.iso_code }}",
    options: {
      CAD: "Canadian Dollar",
      GBP: "Pound Sterling",
      NZD: "New Zealand Dollar",
      DEFAULT: "Default of the region",
    },
    default: "DEFAULT",
  },
  {
    id: "country",
    label: "Country",
    isRuntime: true,
    variable: "{{ localization.country.iso_code }}",
    options: {
      AT: "Austria",
      BE: "Belgium",
      BG: "Bulgaria",
      HR: "Croatia",
      CY: "Cyprus",
      CZ: "Czech Republic",
      DK: "Denmark",
      EE: "Estonia",
      FI: "Finland",
      FR: "France",
      DE: "Germany",
      GR: "Greece",
      HU: "Hungary",
      MC: "Monaco",
      IE: "Ireland",
      IT: "Italy",
      LV: "Latvia",
      LT: "Lithuania",
      LU: "Luxembourg",
      MT: "Malta",
      NL: "Netherlands",
      NO: "Norway",
      PL: "Poland",
      PT: "Portugal",
      RO: "Romania",
      SK: "Slovakia",
      SI: "Slovenia",
      ES: "Spain",
      SE: "Sweden",
      CH: "Switzerland",
      DEFAULT: "Default of the region",
    },
    default: "DEFAULT",
  },
];
