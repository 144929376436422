import queryString from "query-string";
import { shopifyThemeConfigs as settings } from "./shopifyThemeSettings";
import { runningInShopify } from "./runTime";
import { ShopifyConfig as Config } from "./shopifyConfigType";

type Setting<T extends keyof Config> = {
  id: T;
  label: string;
  options: { [K in Config[T]]: string };
  default: Config[T];
} & (
  | {
      isRuntime: false;
      variable: `{{ section.settings.${T} }}`;
    }
  | {
      isRuntime: true;
      variable: string;
    }
);

export type ShopifySetting =
  | Setting<"country">
  | Setting<"currency">
  | Setting<"region">;

const getContainer = () => {
  const titleText = "Configuration Options";
  const containerName = "ha-config-wrapper";

  const container = document.querySelector<HTMLDivElement>(`.${containerName}`);
  if (container) return container;

  const newContainer = document.createElement("div");
  newContainer.className = containerName;

  const title = document.createElement("h2");
  title.className = "ha-configs-title";
  title.innerText = titleText;
  newContainer.appendChild(title);

  document.querySelector(".ha-fab-content")!.appendChild(newContainer);
  return newContainer;
};

const renderConfigMenu = (
  { id, label, options, default: defaultOption }: ShopifySetting,
  selection: ShopifySetting["default"],
  parsedQuery: queryString.ParsedQuery,
  container: HTMLDivElement
) => {
  const s = document.querySelector(`.ha-config-select-${id}`);
  if (s) return selection;

  const title = document.createElement("h3");
  title.classList.add("ha-config-title");
  title.classList.add(`ha-config-title-${id}`);
  title.innerText = label + ":";

  const select = document.createElement("select");
  title.classList.add("ha-config-select");
  title.classList.add(`ha-config-select-${id}`);
  select.id = `${id}Select`;
  select.onchange = (e) => {
    let q = {
      ...parsedQuery,
      [id]: select.value,
    };

    if (select.value == defaultOption) {
      delete q[id];
    }

    const newQuery = queryString.stringify(q);

    if (location.search !== newQuery) {
      location.search = newQuery;
    }
  };

  const br = document.createElement("br");

  container.appendChild(title);
  container.appendChild(select);
  container.appendChild(br);

  for (const key in options) {
    const option = document.createElement("option");
    option.value = key;
    option.text = options[key];
    select.appendChild(option);
  }

  select.value = selection;
  return selection;
};

const getConfig = () => {
  const s = {} as Config;
  if (runningInShopify()) {
    for (let i = 0; i < settings.length; i++) {
      const setting = settings[i];
      const parsed = setting.variable;
      s[setting.id as any] = setting.options[parsed]
        ? (parsed as keyof ShopifySetting["options"])
        : setting.default;
    }
  } else {
    const parsedQuery = queryString.parse(location.search);

    const container = getContainer();

    for (let i = 0; i < settings.length; i++) {
      const setting = settings[i];
      const parsed =
        (parsedQuery[setting.id] as string | undefined) ?? setting.default;
      const validated = setting.options[parsed]
        ? (parsed as keyof ShopifySetting["options"])
        : setting.default;
      s[setting.id as any] = validated;

      renderConfigMenu(setting, validated, parsedQuery, container);
    }
  }

  return s;
};

export const shopifyConfig = getConfig();
